<template>
  <el-card class="class-admin">
    <div slot="header" class="clearfix">
      <span>客户信息管理</span>
      <!-- 添加班级可以通过嵌套路由实现 -->
      <!-- <el-button style="float: right; padding: 3px 0" type="text"
        ><router-link to="/studentAdmin/editInfo">添加班级</router-link></el-button
      > -->

      <!-- 添加班级也可以通过本页面弹出一个组件来实现 -->
      <!-- <el-button
        style="float: right; padding: 3px 0"
        type="text"
        @click="handleAdd"
        >添加客户信息</el-button
      > -->
    </div>

    <!-- 筛选客户 -->
    <select-info @on-select="onSelect" @on-reset="onReset" @on-exportData="exportData"></select-info>
    <el-divider></el-divider>
    <input type="file" @change="handleFileInput($event.target.files)">
    <!-- <el-button type="primary" @change="handleFileInput($event.target.files)" >导入数据</el-button> -->
    <el-button type="primary" @click="exportData">批量导出</el-button>
    <el-button type="primary" @click="checkAll">全选</el-button>
    <el-checkbox class="quanxuan" v-model="checked"></el-checkbox>
    <el-button style="margin-left: 50px;" type="danger" @click="delAllInfoFn">一键删除</el-button>
    <!-- 显示表格 -->
    <el-table :data="tableData" style="width: 100%" @selection-change="handleSelectionChange" ref="table">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="id" label="序号"> </el-table-column>
      <el-table-column prop="username" label="客户姓名"> </el-table-column>
      <el-table-column prop="mobile" label="客户电话"></el-table-column>
      <el-table-column prop="address" label="客户地址"></el-table-column>
      <el-table-column prop="date" label="咨询日期">
        <template slot-scope="scope">
          {{ scope.row.date | formatDate }}
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination v-if="tableData.length" background layout="prev, pager, next" :page-size="14" :total="total"
      @current-change="handlePageChange" :current-page="page">
    </el-pagination>
    <!--     编辑客户信息   -->

    <edit-info @close="handleClose" :dialog-visible="dialogVisible" :action="action" :record="record"></edit-info>
  </el-card>
</template>

<script>
// import { export_json_to_excel } from '../../api/Export2Excel'; // 引入Excel插件
// import XLSX from 'xlsx'; // 引入XLSX库，用于导出Excel
import * as XLSX from 'xlsx'
import editInfo from "./editInfo.vue";
import selectInfo from "./selectInfo";
import { getInfo, delInfo, addInfo,delAllInfo } from "@/api/http";
import moment from "moment";
export default {
  components: { editInfo, selectInfo },
  data() {
    return {
      checked: false,
      ExcelListData: [],
      selectedData: [], // 存储选中的数据
      dialogVisible: false,
      tableData: [],
      // 数据总条数
      total: 0,
      action: "",
      record: {},
      page: 1,
      // selectedHeader: ["id", '客户姓名', '客户电话', '性别', '年龄', '症状', '地区', '咨询日期']
    };
  },

  created() {
    this.handleGetClassList();
  },
  filters: {
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  watch: {
    checked() {
      if (this.checked) {
        this.getExcelList()
      }
    }
  },
  methods: {
    // 处理选中的数据
    handleSelectionChange(val) {
      this.selectedData = val;
      // console.log(this.selectedData);
    },
    // 选中全部数据
    checkAll() {
      this.checked = !this.checked
    },
    async getExcelList() {
      let queryExcel = {}
      if (this.queryData) {
        delete this.queryData.page
        queryExcel = this.queryData
      }
      let res = await getInfo(queryExcel)
      this.selectedData = res.data.list
    },
    // 导入数据
    readExcel(file) {
      let that = this
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = XLSX.read(data, { type: 'binary' })
        workbook.SheetNames.forEach(sheet => {
          const worksheet = workbook.Sheets[sheet]
          // console.log(XLSX.utils.sheet_to_json(worksheet))
          const NewData = XLSX.utils.sheet_to_json(worksheet)
          console.log(NewData);
          // const  readData = async function(){
          if (NewData) {
            for (let i = 0; i < NewData.length; i++) {
              async function readData() {
                let res = await addInfo(NewData[i])
                let { code } = res.data
                if (code == 0) {
                  // that.$message.success("导入成功")
                  // that.open1()
                } else {
                  // that.$message.error('数据导入失败')
                }
              }
              readData()
            }
            that.$message.success("导入成功")

          } else {
            that.$message.error('数据不能为空')
          }
        })
        this.handleGetClassList()
      }
      reader.readAsBinaryString(file)
    },
    handleFileInput(files) {
      const file = files[0]
      this.readExcel(file)
    },

    // 导出数据
    exportData() {
      if (!this.selectedData.length == 0) {
        // const selectedHeader = this.selectedHeader
        // const  header = ["id", '客户姓名', '客户电话', '性别', '年龄', '症状', '地区', '咨询日期']
        // const worksheet = XLSX.utils.json_to_sheet(this.selectedData,{ header }); // 把数据转成sheet
        this.selectedData.forEach(item => {
          item.date = moment(item.date).format('YYYY-MM-DD HH:mm:ss')
        })
        const worksheet = XLSX.utils.json_to_sheet(this.selectedData); // 把数据转成sheet
        const workbook = XLSX.utils.book_new(); // 创建工作簿
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1'); // 把sheet添加到工作簿
        XLSX.writeFile(workbook, 'data.xlsx'); // 导出Excel文件
      } else {
        this.$message.error('请选择要导出的数据')
      }
    },
    // 删除全部数据
     delAllInfoFn(){
      this.$confirm('此操作将永久删除所有数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res =await delAllInfo()
          await this.handleGetClassList()
          console.log(res);
          if(res.data.code==1){
            this.$message.success('删除成功')
          }else if(res.data.code==0){
            this.$message.error('没有数据，删除失败')
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    // 当子组件重置时，拉取所有数据
    onReset() {
      this.page = 1
      this.queryData = ''
      this.handleGetClassList()
    },
    // 当子组件触发查询时
    onSelect(queryData) {
      this.page = 1
      this.queryData = queryData
      this.handleGetClassList()
    },
    // 拉取数据
    // 分析： 在什么情况下需要拉取数据
    //  1. 不存在筛选，仅仅是拉取指定页的数据  传参只有page
    //  2. 筛选触发的拉取数据， 传参： 所有的筛选条件 (先不加page)
    async handleGetClassList() {
      // 重置全选按钮
      this.checked = false
      // console.log('queryData', this.queryData);
      let query = {}
      // 区分在不同情况下传递不同参数
      if (this.queryData) {
        //有筛选
        query = this.queryData
      }
      //无论是否有筛选，都要加上页码
      query.page = this.page
      let res = await getInfo(query);
      let { code, list, total } = res.data;
      // console.log(res.data);
      if (code === 0) {
        this.tableData = list;
        this.total = total;
      }
      if (code === 1) {
        this.tableData = []
      }
    },
    // 处理关闭弹窗
    handleClose(status, type) {
      this.dialogVisible = status;
      if (type === "add") {
        // 添加成功后拉取第一页的数据
        this.page = 1;
        this.queryData = ''
      }
      this.handleGetClassList();
    },
    //当页码改变时
    handlePageChange(page) {
      this.page = page;
      this.handleGetClassList();
    },
    //当编辑的时候
    handleEdit(index, row) {
      this.dialogVisible = true;
      this.action = "edit";
      this.record = row;
    },
    //当删除
    async handleDelete(index, row) {
      this.$confirm('此操作将永久删除该条数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          let res = await delInfo({
        id: row.id,
      });
      let { code } = res.data;
      if (code === 0) {
        this.$message.success("删除成功");
        //判断当前记录是否是所有记录的最后一条
        // page = 5  total= 9
        // bug:  如果最后一页只有一条记录，现在删除这条记录时，本来应该拉取前一页的信息，但现在停留在当前页
        if (this.total == (this.page - 1) * 4 + 1) {
          this.page = this.page - 1;
        }
        // bug: 如果只剩一条数据，也就是第一页只有一条记录，这条记录要删除
        if (this.total == 1) {
          this.tableData = [];
          return;
        }
        this.handleGetClassList();
      } else {
        this.$message.success("删除失败");
      }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    //当添加
    handleAdd() {
      this.dialogVisible = true;
      this.action = "add";
      this.record = {
        classStatus: 0,
      };
    },
  },
};
</script>

<style>
.quanxuan {
  margin-left: 10px;
}
</style>

<template>
  <el-dialog
    :title="action === 'add'? '添加客户信息':'编辑客户信息'"
    :visible="dialogVisible"
    width="80%"
    class="edit-class"
    :before-close="handleClose"
  >
    <!--
        添加班级的表单
     -->
    <el-form :model="form" label-width="120px" ref="form">
      <el-form-item label="客户姓名" prop="username">
        <el-input v-model="form.username" autocomplete="off"></el-input>
      </el-form-item>

      <el-form-item label="用户电话" prop="mobile">
      <el-input v-model="form.mobile" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="客户地址" prop="address">
      <el-input v-model="form.address" autocomplete="off"></el-input>
    </el-form-item>
      <el-form-item label="咨询日期" prop="date">
        <el-date-picker
          v-model="form.date"
          type="datetime"
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>


    </el-form>

    <!-- 底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="add" v-if="action==='add'">添加</el-button>
      <el-button type="primary" @click="edit" v-else>编辑</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from "moment";
import _ from 'lodash'
import { addInfo,editInfo } from "@/api/http";
export default {
  props: {
    dialogVisible: Boolean,
    action: String,
    record: Object
  },
  data() {
    return {
      form: {
        username: "",
        mobile: "",
        date: "",
        address:"",
      },
    };
  },
  watch: {
    record(){
      this.form = _.cloneDeep(this.record)
    }
  },
  methods: {
    // 添加
    add() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //当数据都有效时，调用后端接口，进行登录验证
          this.form.date = moment(this.form.date).format(
            'YYYY-MM-DD hh:mm:ss'
          );
          let res = await addInfo(this.form);
          // console.log(res);
          let {code} = res.data
            if (code == 0){
                this.$message.success("添加成功")
                // 1. 添加成功之后的关闭，要让父组件拉取最新的数据
                this.$emit("close", false,'add');
                this.$refs["form"].resetFields()
            }else{
                this.$message.error("添加失败")
            }

        } else {
          // 数据无效
          //当有字段数据无效时，阻止提交
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 编辑
    edit() {
      if (JSON.stringify(this.record) === JSON.stringify(this.form)){
        this.handleClose()
        return;
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //当数据都有效时，调用后端接口，进行登录验证
          this.form.date = moment(this.form.date).format(
            'YYYY-MM-DD HH:mm:ss'
          );
          let res = await editInfo(this.form);
          // console.log(res);
          let {code} = res.data
            if (code == 0){
                this.$message.success("编辑成功")
                // 1. 编辑成功之后的关闭，要让父组件拉取最新的数据
                this.$emit("close", false,'edit');
                this.$refs["form"].resetFields()
            }else{
                this.$message.success("编辑失败")
            }

        } else {
          // 数据无效
          //当有字段数据无效时，阻止提交
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      // 2. 仅仅关闭弹窗
      this.$emit("close", false);
    },
  },
};
</script>

<style>
</style>

<template>
  <el-form :model="form" label-width="120px" ref="form" :inline="true">
    <el-form-item label="客户姓名" prop="username">
      <el-input v-model="form.username" autocomplete="off"></el-input>
    </el-form-item>

    <el-form-item label="用户电话" prop="mobile">
      <el-input v-model="form.mobile" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="客户地址" prop="address">
      <el-input v-model="form.address" autocomplete="off"></el-input>
    </el-form-item>
    <el-form-item label="咨询日期" prop="date">
      <!-- <el-date-picker
        v-model="form.date"
        type="date"
        placeholder="选择日期"
      >
      </el-date-picker> -->
      <el-date-picker
      v-model="form.startDate"
      type="datetime"
      placeholder="开始日期"
      :picker-options="{ firstDayOfWeek: 1 }"
      style="width: 180px;"
    ></el-date-picker>
    <el-date-picker
      v-model="form.endDate"
      type="datetime"
      placeholder="结束日期"
      :picker-options="{ firstDayOfWeek: 1 }"
      style="width: 180px;"
    ></el-date-picker>
    </el-form-item>
    <el-form-item class="padding">
      <el-button type="primary" @click="onSelect">查询</el-button>
      <el-button type="primary" @click="onReset">重置</el-button>
      <!-- <el-button type="primary" @click="sun">重置</el-button> -->
    </el-form-item>
  </el-form>
</template>
<script>
import moment from 'moment'
// import XLSX from 'xlsx'; // 引入XLSX库，用于导出Excel
export default {
  data() {

    return {
      form: {
        username: "",
        mobile: "",
        date: "",
        address:"",
      },
    };
  },
  methods: {
    // sun(){
    //   console.log(this.form);
    // },
    exportData(){
      this.$emit("on-exportData")
    },
    onReset() {
      this.$refs["form"].resetFields();
      this.$emit("on-reset")
    },
    //   当提交查询的时候
    onSelect() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          //当数据都有效时，调用后端接口，进行登录验证
          this.form.date = this.form.date && moment(this.form.date).format(
           ' YYYY-MM-DD HH:mm:ss'
          );
          this.$emit("on-select",this.form)
        } else {
          // 数据无效
          //当有字段数据无效时，阻止提交
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.padding {
  padding-left: 20px;
}
</style>
